import { Component, HostListener, Input } from '@angular/core';

@Component({
  selector: 'app-application-menu',
  templateUrl: './application-menu.component.html',
  styleUrls:['./application-menu.component.scss']
})

export class ApplicationMenuComponent {
  isOpen = false;

  constructor() {
  }

  // @HostListener('document:click', ['$event'])

  @Input() position = 'app-menu';

  handleDocumentClick(event): void {
    if (this.isOpen) {
      this.toggle();
    }
  }

  toggle(): void {
    this.isOpen = !this.isOpen;
  }

  menuClick(event: MouseEvent): void {
    event.stopPropagation();
  }
}
