// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { UserRole } from '../app/shared/auth.roles';

export const environment = {
  production: false,
  buyUrl : 'https://1.envato.market/6NV1b',
  SCARF_ANALYTICS : false,
  adminRoot: '/app',
  
  
  //imageUrl: 'http://localhost:8200/',
  //apiUrl: 'http://localhost:8200/v3/',
  storeUrl: 'https://store.gobuddy.net/',
  imageUrl: 'https://api.gobuddy.net/',
  apiUrl: 'https://api.gobuddy.net/v3/',
  //storeUrl: 'https://storestaging.gobuddy.net/',
  //imageUrl: 'https://apistaging.gobuddy.net/',
  //apiUrl: 'https://apistaging.gobuddy.net/v3/',
  
  defaultMenuType: 'menu-default',
  subHiddenBreakpoint: 1440,
  menuHiddenBreakpoint: 768,
  themeColorStorageKey: 'vien-themecolor',
  isMultiColorActive: true,
  //defaultColor: 'light.blueyale',
  defaultColor: 'light.greenlime',
  isDarkSwitchActive: true,
  defaultDirection: 'ltr',
  themeRadiusStorageKey: 'vien-themeradius',
  isAuthGuardActive: true,
  defaultRole: UserRole.Admin,
  firebase: {
    apiKey: "AIzaSyAPgZfmhrT6WVkrF6Jan5pqQfVaFIv0pZ0",
    authDomain: "ace-tranquility-358810.firebaseapp.com",
    projectId: "ace-tranquility-358810",
    storageBucket: "ace-tranquility-358810.appspot.com",
    messagingSenderId: "839429296373",
    appId: "1:839429296373:web:5538d15ca7e29273a475bc",
    measurementId: "G-KVER06NK9L"
  }
};
