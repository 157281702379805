import { Injectable } from '@angular/core';
import { loggedIn } from '@angular/fire/auth-guard';
import { timingSafeEqual } from 'crypto';
import { BehaviorSubject, from } from 'rxjs';

import { getUserRole } from 'src/app/utils/util';
import { Helper } from './helper';

export interface ISignInCredentials {
  username: string;
  password: string;
}

export interface ICreateCredentials {
  email: string;
  password: string;
  displayName: string;
}

export interface IPasswordReset {
  code: string;
  newPassword: string;
}

export interface Store {
  _id: string,
  email: string,
  displayName: string,
  country_phone_code: string,
  location: string,
  servertoken: string,
  currency: string
}

@Injectable({ providedIn: 'root' })
export class AuthService {

  loginAdminData: any;
  private loginAdmin = new BehaviorSubject<any>(null);
  loginSession = this.loginAdmin.asObservable();
  private permissions = new BehaviorSubject<any>(null);
  authPermission = this.permissions.asObservable();
  is_main_store_login;
  test = []

  constructor(private helper: Helper) { }

  signIn(credentials: ISignInCredentials) {
    return this.helper.http_post_method_requester(this.helper.POST_METHOD.ADMIN_LOGIN, credentials).then(response_data => {
      if (response_data.success) {
        this.loginAdminData = {
          username: response_data.admin_data.username,
          displayName: response_data.admin_data.username,
          _id: response_data.admin_data._id,
          servertoken: response_data.admin_data.server_token,
          admin_type: response_data.admin_data.admin_type,
          jwt_token: response_data.admin_data.jwt_token
        }
        if (response_data.admin_data.admin_type === 3) {
          this.subStoreSignIn(response_data.admin_data.urls)
          this.is_main_store_login = false;
        } else {
          this.is_main_store_login = true;
          localStorage.setItem('adminData', JSON.stringify(this.loginAdminData));
          // localStorage.setItem('device_token',response_data.device_token);
          this.loginAdmin.next(this.loginAdminData)

        }
      } else {
        if(this.helper.is_use_captcha){
          grecaptcha.reset()
        }
      }
    })
  }

  subStoreSignIn(urls) {
    // subStoreSignIn(credentials: ISignInCredentials){
    // return this.helper.http_post_method_requester(this.helper.POST_METHOD.SUB_STORE_LOGIN, credentials).then(response_data => {
    //   if (response_data.success) {
    //     this.loginAdminData = {
    //         email:response_data.store.email,
    //         displayName:response_data.store.name[0],
    //         _id:response_data.store._id,
    //         location:response_data.store.location,
    //         country_phone_code:response_data.store.country_phone_code,
    //         servertoken:response_data.store.server_token
    //     }
    const permissions = urls
    // urls.forEach((_url) => {
    //   if (_url.permission === 1) {
    //     permissions.push(_url.url)
    //   }
    // })
    this.permissions.next(permissions)
    this.is_main_store_login = false;
    // localStorage.setItem('device_token',response_data.sub_store.device_token);
    localStorage.setItem('adminData', JSON.stringify(this.loginAdminData));
    localStorage.setItem('adminPermissions', JSON.stringify(permissions));
    this.loginAdmin.next(this.loginAdminData)
  }
  // })


  signOut() {
    return new Promise((resolve, rejects) => {
      localStorage.removeItem('adminData');
      localStorage.removeItem('storeData');
      localStorage.removeItem('adminPermissions');
      localStorage.removeItem('cartId');
      this.loginAdminData = undefined;
      this.loginAdmin.next(null);
      this.helper.router.navigate(['/admin/login'])
    });
  }

  autologin() {
    this.loginAdminData = JSON.parse(localStorage.getItem('adminData'));
    if (this.loginAdminData && this.loginAdminData.servertoken) {
      var adminPermissions = JSON.parse(localStorage.getItem('adminPermissions'));
      if (adminPermissions) {
        this.is_main_store_login = false;
        this.permissions.next(adminPermissions)
      } else {
        this.is_main_store_login = true;
      }
      this.loginAdmin.next(this.loginAdminData);
    } else {
      this.signOut()
    }
  }

  register(store: any) {
    return this.helper.http_post_method_requester(this.helper.POST_METHOD.REGISTER, store)
  }

  sendPasswordEmail(json) {
    return this.helper.http_post_method_requester(this.helper.POST_METHOD.FORGOT_PASSWORD, json)
  }

  resetPassword(json) {
    return this.helper.http_post_method_requester(this.helper.POST_METHOD.NEW_PASSWORD, json)
  }

  getStore() {
    // console.log(this.loginAdminData)
    if (this.loginAdminData) {
      return this.loginAdminData;
    } else {
      this.loginAdminData = JSON.parse(localStorage.getItem('adminData'));
      return this.loginAdminData;
    }
  }

  async getUser() {
    return { displayName: this.loginAdminData.displayName, role: getUserRole() };
  }

  otpVerify(json){
    return this.helper.http_post_method_requester(this.helper.POST_METHOD.FORGOT_PASSWORD_VERIFY, json)
  }
}
