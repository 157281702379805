import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-email-verification',
  templateUrl: './email-verification.component.html',
  styleUrls: ['./email-verification.component.scss']
})
export class EmailVerificationComponent implements OnInit {

  status_text = 'Email verification failed!';
  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.queryParams
      .subscribe(params => {
        //console.log(params);
        //this.status_text = params.status;
        if(params.status=='success'){
          this.status_text = 'Your email address has been verified!';
        }
        
        //console.log(this.status_text); // price
      }
    );
  }

}
