import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpResponse, HttpErrorResponse
} from '@angular/common/http';
import { Observable, of } from "rxjs";
import { tap, catchError } from "rxjs/operators";
import { NotificationsService, NotificationType } from 'angular2-notifications';
import * as string_json from 'src/assets/i18n/en.json'
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../shared/auth.service';

@Injectable()
export class ResInterceptInterceptor implements HttpInterceptor {


  toastConfig = { timeOut: 3000, showProgressBar: true, position: ['top', 'right'] };

  constructor(private notifications: NotificationsService, 
    private _auth:AuthService,
    private translate: TranslateService) { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    return next.handle(req).pipe(
      tap(evt => {
        if (evt instanceof HttpResponse && evt.body) {
          if (evt.body.success) {
            if(evt.body.message){
              this.onSuccess(evt.body.message)
            }
          } else {
            if(evt.body.error_code === 999){
              this._auth.signOut()
            }
            if(evt.body.error_code){

              this.onError(evt.body.error_code);
            }
          }
        }
      }),
      catchError((err: any) => {
        if (err instanceof HttpErrorResponse) {
          this.onError('2003');
        }
        return of(err);
      }));

  }

  onError(errorMessage): void {
    // console.log(errorMessage)
    if(string_json.default['error-code'][errorMessage]){
      // errorMessage = string_json.default['error-code'][errorMessage] ? errorMessage : '2003';
    // console.log(errorMessage)
    errorMessage = this.translate.instant('error-code.' + errorMessage)
      this.notifications.create(
        this.translate.instant('alert.error'),
        errorMessage,
        NotificationType.Error,
        this.toastConfig
      );
    }
  }

  onSuccess(successCode): void {
    if (string_json.default['success-code'][successCode]) {
      this.notifications.create(
        this.translate.instant('alert.success'),
        this.translate.instant('success-code.' + successCode),
        NotificationType.Success,
        this.toastConfig
      );
    }
  }
}
