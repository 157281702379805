import { Component, OnInit } from '@angular/core';
import { ProfileService } from 'src/app/services/profile.service';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {

  policy_content: any;
  constructor(private _profileService: ProfileService) { }

  ngOnInit(): void {
    this._profileService.get_provider_privacy_policy().then(res_data=>{
      //console.log(res_data)
      if(res_data.success){
        this.policy_content = res_data.result.provider_privacy
      }
    })
  }

}
