<!-- <div id="root">
  <div class="fixed-background">
    <main>
      <div class="container">
        <div class="row h-100">
          <div class="col-12 col-md-10 mx-auto my-auto">
            <div class="card auth-card">
              <div class="position-relative image-side ">
                <p class="h2">{{ 'heading-title.welcome-to-title' | translate }}{{ 'heading-title.admin-panel' | translate }}</p>
              </div>
              <div class="form-side">
                <a routerLink="/">
                  <img class="w-20 h-auto pb-4" [src]="helper.image_url + '/web_images/logo.png'" alt="Logo">

                </a>
                <h6 class="mb-4">{{ "heading-title.error-title" | translate }}</h6>
                <p class="mb-0 text-muted text-small mb-0">
                  {{ "heading-title.error-code" | translate }}
                </p>
                <p class="display-1 font-weight-bold mb-5">404</p>
                <button [routerLink]="adminRoot" class="btn btn-primary btn-lg btn-shadow" type="submit">
                  {{ "button-title.go-back-home" | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</div> -->


<div class="row h-100" >
  <div class="col-sm-4">

  </div>
  <div class="col-sm-4 text-center" style="margin-top:10%">
    
      <!-- <span class="logo-single"></span> -->
      <img src="assets/logos/black_logo.png" class="text-center " width="80px"  >
   
    <h6 class="mb-4 mt-4">Ooops... looks like page not found!</h6>
    <p class="display-1 font-weight-bold mb-5">404</p>
    <button [routerLink]="adminRoot" class="btn btn-primary btn-lg btn-shadow" type="submit">
      Go Back
    </button>
  </div>
  <div class="col-sm-4">
    
  </div>

</div>

