import { Injectable, NgZone } from '@angular/core';
import { METHODS,GET_METHOD } from './http_methods';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { DEFAULT_IMAGE_PATH,
        ORDER_STATE,
        DAY,
        WEEK,
        MONTH,
        USER_TYPE,
        ADMIN_PROMO_FOR_ID,
        PROMO_RECURSION,
        DATE_FORMAT,
        DELIVERY_TYPE,
        ADMIN_PROFIT_ON_DELIVERYS,
        PROMO_FOR_ID,
        ADMIN_PROMO_FOR_STRING,
        ADMIN_PROFIT_ON_ORDER,
        CHARGE_TYPE,
        WALLET_REQUEST_STATUS,
        ADMIN_URL,
        ADMIN_DATA_ID,DELIVERY_TYPE_CONSTANT,
        WALLET_COMMENT_ID } from 'src/app/shared/constant'
import * as json2csv from 'json2csv'
import { saveAs } from 'file-saver';
import { BehaviorSubject, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService, NotificationType } from 'angular2-notifications';

export interface IResponse {
   success: boolean,
   message: string,
   data: any
}

@Injectable({ providedIn: "root" })
export class Helper {
  store_url = environment.storeUrl;
  base_url = environment.apiUrl;
  public image_url = environment.imageUrl;
  public POST_METHOD = METHODS;
  public ADMIN_DATA_ID = ADMIN_DATA_ID;
  public WALLET_COMMENT_ID = WALLET_COMMENT_ID;
  public GET_METHOD = GET_METHOD;
  public METHODS = METHODS;
  public ORDER_STATE = ORDER_STATE;
  public USER_TYPE = USER_TYPE;
  public DEFAULT_IMAGE_PATH = DEFAULT_IMAGE_PATH;
  public DATE_FORMAT = DATE_FORMAT;
  public DELIVERY_TYPE = DELIVERY_TYPE;
  public DELIVERY_TYPE_CONSTANT = DELIVERY_TYPE_CONSTANT;
  public ADMIN_PROFIT_ON_DELIVERYS = ADMIN_PROFIT_ON_DELIVERYS;
  public ADMIN_PROFIT_ON_ORDER = ADMIN_PROFIT_ON_ORDER;
  public CHARGE_TYPE = CHARGE_TYPE;
  public ADMIN_PROMO_FOR_STRING = ADMIN_PROMO_FOR_STRING;
  public PROMO_FOR_ID = PROMO_FOR_ID;
  public ADMIN_PROMO_FOR_ID = ADMIN_PROMO_FOR_ID;
  public PROMO_RECURSION = PROMO_RECURSION;
  public MONTH = MONTH;
  public WEEK = WEEK;
  public DAY = DAY;
  public WALLET_REQUEST_STATUS = WALLET_REQUEST_STATUS
  public height;
  public width;
  public ADMIN_URL= ADMIN_URL
  public _translateService: any

  selected_store_id = '';
  selected_store;
  changeSelectedStore = new BehaviorSubject<any>(null);
  is_loading = false
  toastConfig = { timeOut: 3000, showProgressBar: true };
  is_use_captcha: boolean = false;

  constructor(public http: HttpClient,
    private _notificationService: NotificationsService,
    public router: Router,
    public ngZone:NgZone,
    private _trans : TranslateService) {
    this._translateService = this._trans
   }

  http_post_method_requester(api_name, parameter) {
    this.is_loading = true;
    return this.http.post<any>(this.base_url + api_name, parameter).toPromise().then(data => {
      setTimeout(() => {
        if (this.is_loading) {
          this.is_loading = false;
        }
      }, 1000);
      return data;
    })
  }

  http_get_method_requester(api_name, parameter) {
    return this.http.get<any>(this.base_url + api_name).toPromise()
  }

  http_get_method_requester_raw(api_name, parameter) {
    return this.http.get<any>(api_name).toPromise()
  }

  changeSelectStore(store){
    this.selected_store_id = store._id;
    this.selected_store = store
    this.changeSelectedStore.next(store._id);
  }

  phone_number_validation(evt) {
    var charCode = (evt.which) ? evt.which : evt.keyCode
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  public storeFormattedId(unique_id){
    return  "STR"+("000000000000" + unique_id).slice(-11);
  }
  public formattedId(str_prefix, unique_id){
    return  str_prefix+("000000000000" + unique_id).slice(-11);
  }
  replaceDoubleInvertedComma(str){
    return str.toString().replaceAll('"','');
  }

  public downloadcsvFile(data:any, fields, file_name='data.csv'){
    if (fields.length !== 0) {
      fields.forEach(field => {
        field.label = this._trans.instant(field.label)
      });
      let csvData = this.convertToCSV(data, fields);
      let file = new Blob([csvData], { type: 'text/csv;charset=utf-8' });
      //saveAs(file, "data.csv");
      saveAs(file, file_name);
    } else {
      this._notificationService.create(
        this._trans.instant('alert.error'),
        this._trans.instant('label-title.no-data-found'),
        NotificationType.Error,
        this.toastConfig
      )
    }
  }

  public convertToCSV(objArray: any, fields) {
      let json2csvParser = new json2csv.Parser({ fields: fields});
      let csv = json2csvParser.parse(objArray);
      return csv;
  }

  space_validation(evt){
    if(evt.code == "Space" && evt.target.value.length < 1){
      return false;
    }
    return true
  }

  number_validation(evt) {
    var charCode = (evt.which) ? evt.which : evt.keyCode
    //console.log(charCode);
    //console.log(evt);
    if((charCode > 95 && charCode < 106) && charCode != 101){
      return true
    }
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      if (charCode == 46) {
        return true;
      }
      else {
        return false;
      }
    }
    return true;
  }

  special_char_validation(event) {
    var k;
    k = event.charCode;
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
  }


  export_csv(data, fieldname,filename='data') {
    if (data.length !== 0) {
      var date_name: any = new Date().toLocaleDateString();
      date_name.replaceAll("/", "_");
      filename = filename + '_' + date_name;
      let csvData = this.convert_to_csv(data, fieldname);
      let blob = new Blob(['\ufeff' + csvData], { type: 'text/csv;charset=utf-8;' });
      let dwldLink = document.createElement("a");
      let url = URL.createObjectURL(blob);
      let isSafariBrowser = navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1;
      if (isSafariBrowser) {
        dwldLink.setAttribute("target", "_blank");
      }
      dwldLink.setAttribute("href", url);
      dwldLink.setAttribute("download", filename + ".csv");
      dwldLink.style.visibility = "hidden";
      document.body.appendChild(dwldLink);
      dwldLink.click();
      document.body.removeChild(dwldLink);
    }
  }

  private convert_to_csv(objArray, headerList) {
    let array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    let row = 'SR NO,';
    for (let index in headerList) {
      var dump = headerList[index].replaceAll("_"," ")
      row += dump.toUpperCase() + ',';
    }
    row = row.slice(0, -1);
    str += row + '\r\n';
    for (let i = 0; i < array.length; i++) {
        let line = (i+1)+'';
        for (let index in headerList) {
            let head = headerList[index];
            if(array[i][head] && array[i][head].toString().includes(",")){
              line += ',' + array[i][head].toString().replaceAll(',','');
            }else{
              line += ',' + array[i][head];
            }
        }
        str += line + '\r\n';
    }
    return str;
  }

  get uuid() : string {
    return 'xxxxxxxx-xxxx-xxx-xxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  set_dynamic_height_width(class_name = '.dynamic_image'){
    this.width = document.querySelector(class_name).clientWidth;
    this.height = document.querySelector(class_name).clientHeight;
  }

  clear_height_width(){
    this.height = this.width = undefined;
  }

  get_image(image_url) : string{
    return (this.height && this.width)  ? environment.imageUrl+'resize_image?width='+this.width+'&height='+this.height+'&format=webp&image='+image_url : null;
  }

  getWeekDay(date){
    date = new Date(date);
    var first = date.getDate() - date.getDay();
    var start_date:any = new Date(date.setDate(first))
    var last = first + 6;
    var end_date:any = new Date(date.setDate(last))
    if(first <= 0){
      end_date = new Date(date.setDate(last))
      end_date = new Date(new Date(end_date).setMonth(new Date(end_date).getMonth() + 1))
    }else{
      end_date = new Date(date.setDate(last))
    }
    start_date = new Date(new Date(start_date).setHours(0,0,0,0)).toUTCString()
    end_date = new Date(new Date(end_date).setHours(0,0,0,0)).toUTCString()
    return [start_date,end_date];
  }

  findnearest(value){
    value = Math.abs(Math.ceil(value));
    let length = value.toString().length;
    if(length === 1){
      return length;
    }else{
      var test1 = "1";
      for (let index = 0; index < length -1 ; index++) {
        test1 = test1 + "0";
      }
      var test2 = value % Number(test1);
      var test3 = value - test2;
      var test4:any = test2.toString().length === length - 1 ? Number(test2.toString()[0]) + 1 : 1;
      for (let index = 0; index < length - 2 ; index++) {
        test4 = test4 + "0";
      }
      var final = Number(test3) + Number(test4);
      return final
    }

  }

  get_order_status(order_status){
    switch(order_status){
      case(1): {
        return 'Waiting For Store To Accept'
      }
      case(101): {
        return 'Cancelled By User'
      }
      case(3): {
        return 'Store Accepted'
      }
      case(103): {
        return 'Store Rejected'
      }
      case(104): {
        return 'Store cancelled'
      }
      case(105): {
        return 'Store Cancelled Reqeust'
      }
      case(5): {
        return 'Store Preparing Order'
      }
      case(7): {
        return 'Oreder Ready'
      }
      case(9): {
        return 'Waiting For Deliveryman'
      }
      case(109): {
        return 'No Deliveryman Found'
      }
      case(111): {
        return 'Deliveryman rejected'
      }
      case(25): {
        return 'Order Completed'
      }
      case(112): {
        return 'Deliveryman Cancelled'
      }
      case(13): {
        return 'Deliveryman Comming'
      }
      case(15): {
        return 'Deliveryman Arrived'
      }
      case(17): {
        return 'Deliveryman Picked Order'
      }
      case(19): {
        return 'Deliveryman Started Delivery'
      }
      case(21): {
        return 'Deliveryman Arrived At Address'
      }
      case(23): {
        return 'Deliveryman Completed Delivery'
      }
    }
  }

  get_insurance_claim_status(claim_status){
    switch(claim_status){
      case('1'): {
        return 'Claim received'
      }
      case('2'): {
        return 'Claim being processed'
      }
      case('3'): {
        return 'Claim finalised'
      }
      case('4'): {
        return 'Claim paid'
      }
      case('5'): {
        return 'Claim rejected'
      }
     
    }
  }

  downloadImage(url: string, fileName: string) {
      const a: any = document.createElement('a');
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.style = 'display: none';
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
  };
  downloadUrl(url: string){
    //console.log('downloadUrl')
    return this.http.get(url, { responseType: 'blob' }).pipe(switchMap(response => this.readFile(response)));
  }

  private readFile(blob: Blob): Observable<string> {
    return Observable.create(obs => {
      const reader = new FileReader();
      reader.onerror = err => obs.error(err);
      reader.onabort = err => obs.error(err);
      reader.onload = () => obs.next(reader.result);
      reader.onloadend = () => obs.complete();
      return reader.readAsDataURL(blob);
    });
  }
}