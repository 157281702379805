import { Injectable } from '@angular/core';
import { Helper } from '../shared/helper';
import { AuthService,Store } from '../shared/auth.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CommonService {

  private store:Store;
  
  constructor(private helper: Helper,private _auth:AuthService) {
    this.store = this._auth.getStore();
  }

  private _providerChanges = new BehaviorSubject<any>(null);
  _providerObservable = this._providerChanges.asObservable()

  fetch_image_settings() {
    this.store = this._auth.getStore();
    let json = {
      store_id : this.store._id,
      server_token:this.store.servertoken
    }
    return this.helper.http_post_method_requester(this.helper.POST_METHOD.GET_IMAGE_SETTING, json)
  }

  courier_data_list(json){
    return this.helper.http_get_method_requester(this.helper.POST_METHOD.COURIER_DATA_LIST, json)
  }

  get_country_list(){
    
    return this.helper.http_get_method_requester(this.helper.GET_METHOD.GET_COUNTRY_LIST, {})
  }

  get_mode_of_transport_list(){
    return this.helper.http_get_method_requester(this.helper.GET_METHOD.GET_MODE_OF_TRANSPORT_LIST, {})
  }

  order_detail(json){
    return this.helper.http_post_method_requester(this.helper.POST_METHOD.ORDER_DETAIL, json)
  }

  last_six_months_earnings(){
    return this.helper.http_post_method_requester(this.helper.POST_METHOD.LAST_SIX_MONTH_EARNING_DETAIL, {})
  }

  last_fifteen_day_order_detail(){
    return this.helper.http_post_method_requester(this.helper.POST_METHOD.LAST_FIFTEEN_DAY_ORDER_DETAIL, {})
  }

  last_six_month_payment_detail(){
    return this.helper.http_post_method_requester(this.helper.POST_METHOD.LAST_SIX_MONTH_PAYMENT_DETAIL, {})
  }

  
  claim_delivery_chart_data(json){
    return this.helper.http_post_method_requester(this.helper.POST_METHOD.CLAIM_DELIVERY_CHART_DATA, json)
  }

  insurance_claim_status_percentage(){
    return this.helper.http_get_method_requester(this.helper.GET_METHOD.GET_INSURANCE_CLAIM_STATUS, {})
    //return this.helper.http_get_method_requester(this.helper.GET_METHOD.GET_INSURANCE_CLAIM_DATA, {})
    
  }

  fulfillUnfulfillChart(json){
    return this.helper.http_get_method_requester(this.helper.GET_METHOD.FULFILLED_UNFULFILLED, json)
  }

  unsuccessfulPayments(json){
    return this.helper.http_get_method_requester(this.helper.GET_METHOD.UNSUCCESSFULL_PAYMENTS, json)
  }

  transporterReviews(json){
    return this.helper.http_get_method_requester(this.helper.GET_METHOD.TRANSPORTER_REVIEWS, json)
  }
  

  dailyTransporterDeliveryChart(json){
    return this.helper.http_get_method_requester(this.helper.GET_METHOD.DAILY_TRANSPORTER_DELIVERIES, json)
  }

  financialsChart(json){
    return this.helper.http_get_method_requester(this.helper.GET_METHOD.FINANCIALS, json)
  }
  

  gobuddyIncomeChart(json){
    return this.helper.http_get_method_requester(this.helper.GET_METHOD.GOBUDDY_INCOME, json)
  }
  
  retailerPeerToPeerChart(json){
    return this.helper.http_get_method_requester(this.helper.GET_METHOD.RETAILER_VS_PEER_DELIVERIES, json)
  }

  register_details_counts(){
    return this.helper.http_get_method_requester(this.helper.GET_METHOD.REGISTER_DETAILS_COUNT, {})
  }
  delivery_details_counts(){
    return this.helper.http_get_method_requester(this.helper.GET_METHOD.DELIVERY_DETAILS_COUNT, {})
  }
  financials_counts(){
    return this.helper.http_get_method_requester(this.helper.GET_METHOD.FINANCIALS_COUNT, {})
  }

  monthly_payment_detail(){
    return this.helper.http_post_method_requester(this.helper.POST_METHOD.MONTHLY_PAYMENT_DETAIL, {})
  }

  get_app_name(){
    return this.helper.http_get_method_requester(this.helper.GET_METHOD.GET_APP_NAME, {})
  }

  add_wallet_amount(json){
    return this.helper.http_post_method_requester(this.helper.POST_METHOD.ADD_WALLET, json).then(() => {
      this._providerChanges.next({})
    })
  }

  add_langauge(form_data){
    return this.helper.http_post_method_requester(this.helper.POST_METHOD.ADD_NEW_LANGUAGE, form_data)
  }

  getSeoTags(tabType) {
    return this.helper.http_post_method_requester(this.helper.POST_METHOD.GET_SEO_TAGS, {type: tabType})
  }
}
