import { Component, OnInit, Renderer2, AfterViewInit } from '@angular/core';
import { LangService } from './shared/lang.service';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { AuthService } from './shared/auth.service';
import { CommonService } from './services/common.service';
import { NavigationEnd, Router } from '@angular/router';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})

@Injectable()
export class AppComponent implements OnInit, AfterViewInit {
  isMultiColorActive = environment.isMultiColorActive;
  constructor(private langService: LangService, 
    private renderer: Renderer2,
    private authService:AuthService,
    private commonService: CommonService,
    public router: Router,) {
    this.getAdminData()
  }

  ngOnInit(): void {
    
    //this.authService.autologin()
    this.langService.init();
    /* Added by dc*/
    
    setTimeout(() => {
      //console.log('current url:',this.router.url);
      if(this.router.url=='/admin/forgot-password' || this.router.url=='/privacy-policy' ||  this.router.url=='/email-verified' ||  this.router.url=='/email-verified?status=success' ||  this.router.url=='/email-verified?status=failed' ){
        //no action
      }else if(this.router.url=='/'){
        var adminSessionData = JSON.parse(localStorage.getItem('adminData'));
        if (adminSessionData && adminSessionData.servertoken) {
          this.authService.autologin()
        }
      }else{
        this.authService.autologin()
      }
    }, 500)
    
    
    /* End dc code*/
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.renderer.addClass(document.body, 'show');
    }, 1000);
    setTimeout(() => {
      this.renderer.addClass(document.body, 'default-transition');
    }, 1500);
  }

  getAdminData(){
    var app_name = localStorage.getItem('appName')
    if(app_name){
      this.commonService.get_app_name().then(res_data => {
        if(res_data.app_name !=app_name){
          localStorage.setItem('appName', res_data.app_name)
        }
        
      })
    } else {
      this.commonService.get_app_name().then(res_data => {
        localStorage.setItem('appName', res_data.app_name)
      })
    }

    document.getElementById('fav-icon').setAttribute('href', environment.imageUrl + 'web_images/title_image.png');
  }
}
