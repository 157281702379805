<style>
    body{
        padding-bottom: 0px !important;
    }
</style>
<!DOCTYPE html>
<html>
    <head>
        <title>Privacy Policy</title>
        <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap" rel="stylesheet">
       
    </head>
<body style="display: flex;align-items: center;justify-content: center;background-color: #E5E5E5;">
<div style="background-color:#ffffff;text-align: center;width: 100%;">
<table style="background:#f4f6f5; display: inline-table;width: 950px;" cellspacing="0" cellpadding="0">
  <tr>
    <td>
        <div style="text-align: center;padding: 16px;background-color: rgb(255, 255, 255);">
                <img  style="height: 50%; max-height: 65px;" src="https://storestaging.gobuddy.net/assets/logos/white_logo.png" />
                <!-- &nbsp;&nbsp;&nbsp;<span><h1 style="display: inline; font-weight: 500; font-size: 1.75rem;font-family: 'Poppins', sans-serif;">Gobuddy</h1></span> -->
        </div>
    </td>
  </tr>
  <tr>
      <td>
         <table style="width: 100%;background-color: #FFFFFF;" cellspacing="0" cellpadding="0">
             <!-- <tr>
                 <td style="text-align: center;">
                    <img style="margin-top: 35px;margin-bottom: 35px;" src="./images/completed.svg" />
                 </td>
             </tr> -->
             <tr>
                <td>
                    <div style="background: #FFFFFF;border-radius: 8px; text-align: center;margin: 0 36px;padding: 36px 28px;">
                        <div style="letter-spacing: 0.1px;color: #404040;font-weight: 400;font-size: 16px;text-align: left;margin-top: 24px; text-align: justify;">
                            <!-- <div style="font-family: 'Poppins', sans-serif;font-size: 16px;">Hi <span style="font-weight: 600;color: #040B11;">John!</span></div>
                            <br /> -->
                            <!-- <h3 style="font-size: 24px;margin-top: 0; text-align: center; ">Privacy Policy</h3> -->
                          
                            <div [innerHTML]="policy_content"></div>

                            <!-- <span style="color: #040B11;font-weight: 500;font-size: 20px;line-height: 28px;">The Gobuddy Team</span> -->
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td>
                    <div style="text-align: center;flex-direction: column;margin: 40px 36px 0;">
                        <!-- <div>
                            <a href="https://www.facebook.com/gobuddybusiness/" taget="_bank" style="color: transparent;">
                                <img src="https://apistaging.gobuddy.net/email_images/facebook.png" />
                            </a>
                            <a href="https://www.linkedin.com/company/gobuddybusiness/" taget="_bank" style="color: transparent;display: inline-block;line-height: 1;margin-left: 21px;margin-right: 21px;">
                                <img src="https://apistaging.gobuddy.net/email_images/linkdin.png" />
                            </a>
                        </div> -->
                        <!-- <div style="margin-top: 22px;">
                            <a href="mailto:support@gobuddy.net" style="color: #686464;text-align: center;letter-spacing: 0.03em;font-weight: 500;font-size: 15px;
                            line-height: 28px;text-decoration: none;">support@gobuddy.net</a>   <span style="color: rgba(104, 100, 100, 0.5);">|</span>  <a style="color: #686464;text-align: center;letter-spacing: 0.03em;font-weight: 500;font-size: 15px;
                            line-height: 28px;text-decoration: none;" href="tel:+27 60 054 1091">+27 60 054 1091</a>
                        </div> -->
                        <div style="padding: 22px 0;width: 100%;border-top:1px solid #E5E5E5;margin-top: 24px;">
                            <p style="margin: 0;color: #8F8F8F;text-align: center;letter-spacing: 0.05em;font-weight: 400;font-size: 12px;line-height: 16px;">Copyright 2024@Gobuddy </p>
                        </div>
                    </div>
                </td>
            </tr>
         </table>
      </td>
  </tr>
</table>
</div>
</body>
</html>