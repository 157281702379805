
export var GET_METHOD = {

  GET_COUNTRY_LIST: '/api/admin/get_country_list',
  GET_ALL_COUNTRY: '/admin/get_country_list',
  GET_ADMIN_COUNTRY_LIST: "/admin/country_list",
  GET_SERVER_COUNTRY_LIST: "/admin/get_server_country_list",
  GET_WEIGHT_CATEGORY_PRICE_LIST: "/admin/weight_category_price",
  GET_WEIGHT_CATEGORY_LIST: "/admin/weight_category",
  GET_PRICE_CATEGORY_LIST: "/admin/price_category",
  GET_MODE_OF_TRANSPORT_LIST: "/admin/get_mode_of_transport_list",
  GET_TIMEZONE_LIST: '/admin/get_timezone_list',
  VEHICLE_LIST: '/admin/vehicle_list',
  ALL_DELIVERY_LIST: 'admin/delivery_list',
  ADMIN_LIST: '/admin/lists',
  GET_DELIVERY_LIST: '/api/admin/get_delivery_list',
  GET_APP_NAME: '/admin/get_app_name',
  GET_INSURANCE_CLAIM_DATA: '/admin/insurance_claim_data',
  GET_INSURANCE_CLAIM_STATUS: '/admin/dashboard/insurance_claim_status',
  GET_LANGUAGES: '/admin/get_languages',
  GET_PROVIDERS: '/admin/get_providers',
  PAYMENT_GATEWAY_LIST: '/admin/payment_gateway_list',
  GET_SCRIPT_TAGS: '/api/admin/get_script_page',

  REGISTER_DETAILS_COUNT: '/admin/dashboard/register_details_counts',
  DELIVERY_DETAILS_COUNT: '/admin/dashboard/delivery_details_counts',
  FINANCIALS_COUNT: '/admin/dashboard/financials_counts',
  FULFILLED_UNFULFILLED: '/admin/dashboard/fulfilled_unfulfilled',
  GOBUDDY_INCOME: '/admin/dashboard/gobuddy_income',
  RETAILER_VS_PEER_DELIVERIES: '/admin/dashboard/retailer_vs_peer_deliveries',
  FINANCIALS: '/admin/dashboard/financials',
  DAILY_TRANSPORTER_DELIVERIES: '/admin/dashboard/daily_transporters_deliveries',
  UNSUCCESSFULL_PAYMENTS: 'admin/dashboard/unsuccessfull_payments_transporters',
  TRANSPORTER_REVIEWS: 'admin/dashboard/star_transporter_reviews',
}


export var METHODS = {
  GET_WEIGHT_CATEGORY_PRICE_DETAIL: '/admin/get_weight_category_price_detail',
  GET_WEIGHT_CATEGORY_DETAIL: '/admin/get_weight_category_detail',
  GET_PRICE_CATEGORY_DETAIL: '/admin/get_price_category_detail',
  ADD_WEIGHT_CATEGORY_PRICE_DATA: '/admin/add_weight_category_price',
  ADD_WEIGHT_CATEGORY_DATA: '/admin/add_weight_category',
  ADD_PRICE_CATEGORY_DATA: '/admin/add_price_category',
  UPDATE_WEIGHT_CATEGORY_PRICE_DATA: '/admin/update_weight_category_price',
  UPDATE_WEIGHT_CATEGORY_DATA: '/admin/update_weight_category', 
  UPDATE_PRICE_CATEGORY_DATA: '/admin/update_price_category', 
  UPDATE_PAYMENT_GATEWAY_KEYS: '/api/admin/update_payment_gateway_keys',
  SUB_STORE_LOGIN: '/api/store/sub_store_login',
  LOGIN: '/api/store/login',
  LOGOUT: '/api/store/logout',
  REGISTER: '/api/store/register',
  UPDATE: '/api/store/update',
  GET_STORE_DATA: '/api/store/get_store_data',
  LIST_REVIEWS:'/admin/admin_review_list',
  FORGOT_PASSWORD_VERIFY: '/api/admin/forgot_password_verify',
  NEW_PASSWORD: '/api/admin/new_password',
  DELETE: '/admin/delete',

  ADMIN_LOGIN: '/login',
  LIST_LANGUAGE:'/admin/get_languages',
  PROVIDER_PRIVACY_POLICY: 'api/provider/get_policy_content',
  USER_LIST_SEARCH_SORT: '/admin/user_list_search_sort',
  APPROVE_DECLINE_USER: '/admin/approve_decline_user',
  GET_USER_DETAIL: '/admin/get_user_detail',
  UPDATE_USER: '/admin/update_user',
  ADD_WALLET: '/admin/add_wallet',
  SEND_SMS: '/admin/send_sms',
  SEND_NOTIFICATION: '/admin/send_notification',
  VIEW_DOCUMENT_LIST: '/admin/view_document_list',
  VIEW_DOCUMENT_LIST_DATA: '/admin/provider_document_data',
  GET_PROVIDER_LOCATIONS_DATA: '/admin/provider_locations_data',
  GET_REFERRAL_HISTORY: '/admin/get_user_referral_history',
  GET_USER_REVIEW_HISTORY:   '/admin/get_user_review_history',
  UPLOAD_DOCUMENT: '/admin/upload_document',
  PROVIDER_LIST_SEARCH_SORT: '/api/provider/provider_list_search_sort',
  EXPORT_PROVIDER: '/api/provider/export_provider_data',
  APPROVE_DECLINE_PROVIDER: '/admin/provider_approve_decline',
  GET_PROVIDER_DETAIL: '/admin/get_admin_provider_detail',
  UPDATE_PROVIDER: '/admin/update_provider',
  GET_PROVIDER_REVIEW_HISTORY:   '/admin/get_provider_review_history',
  GET_PROVIDER_BANK_DETAIL: '/admin/get_bank_detail',
  GET_STORE_BANK_DETAIL: '/admin/get_bank_detail',

  STORE_LIST_SEARCH_SORT: '/admin/store_list_search_sort',
  APPROVE_DECLINE_STORE: '/admin/approve_decline_store',
  GET_STORE_DETAIL: '/admin/get_admin_store_detail',
  UPDATE_STORE: '/admin/update_store',
  GET_STORE_REVIEW_HISTORY:   '/admin/get_store_review_history',
  ALL_DELIVERY_LIST: '/admin/delivery_list',
  PROMO_CODE_LIST: '/admin/promo_code_list',
  GET_PROMO_USES_DETAIL: '/admin/get_promo_uses_detail',
  ADD_PROMO: '/admin/add_promo_code_data',
  UPDATE_PROMO: '/admin/update_promo_code',
  GET_PROMO_DETAIL: '/admin/get_promo_detail',
  UPDATE_PROMO_IMAGE: '',
  CHECK_PROMO: '',
  ADD_STORE_NOTES: '/api/store/add_store_notes',
  GET_STORE_NOTES: '/api/store/get_store_notes',
  UPDATE_SPECIFICATIONS_NAME: '/api/store/update_specification_name',
  UPDATE_SP_NAME: '/api/admin/update_sp_name',
  FORGOT_PASSWORD: '/api/admin/forgot_password',
  GET_CITY_LIST: '/api/admin/get_city_list',
  GET_STORE_LIST_BY_DELIVERY: '/admin/get_store_list_by_delivery',
  CITY_LIST: '/admin/city_list',
  GET_DISPATCHER_ORDER_LIST: "/api/store/get_dispatcher_order_list",
  GET_COUNTRY_DATA: "/admin/get_country_data",
  DELETE_COUNTRY: "/admin/delete_country",
  ADD_COUNTRY_DATA:"/admin/add_country_data",
  UPDATE_COUNTRY:"/admin/update_country",
  GET_COUNTRY_TIMEZONE:"/admin/get_country_timezone",
  ADD_CITY_DATA:"/admin/add_city_data",
  CHECK_CITY: "/admin/check_city",
  UPDATE_CITY_DATA: "/admin/update_city",
  GET_CITY_DETAIL:"/admin/get_city_detail",
  GET_WALLET_DETAIL:"/admin/get_wallet_detail",
  GET_SETTING_DETAIL: '/api/admin/get_setting_detail',
  GET_INSTALLATION_SETTING: '/api/admin/get_app_keys',
  UPDATE_ADMIN_SETTING: '/api/admin/update_admin_setting',
  UPDATE_INSTALLATION_SETTING: '/api/admin/update_installation_setting',
  COUNTRY_DETAIL_FOR_ADMIN: '/admin/country_detail_for_admin',
  UPLOAD_LOGO_IMAGES: '/admin/upload_logo_images',
  UPDATE_PUSH_NOTIFICATION_SETTING: '/admin/update_push_notification_setting',
  STORE_LIST_FOR_MAP: '/admin/store_list_for_map',
  PROVIDER_LIST_FOR_MAP: '/admin/provider_list_for_map',
  GET_MASS_NOTIFICATION_LIST: '/admin/get_mass_notification_list',
  CREATE_MASS_NOTIFICATION: '/admin/create_mass_notifications',
  SMS_LIST: '/admin/sms_list',
  GET_FINANCIAL_DETAIL: '/admin/financials',
  UPDATE_FINANCIAL_DETAIL: '/admin/update_financials',
  EMAIL_LIST: '/admin/email_list',
  UPDATE_SMS: '/admin/update_sms',
  UPDATE_EMAIL: '/admin/update_email',
  UPDATE_EMAIL_CONFIGURATION: '/admin/update_email_configuration',
  GET_SMS_GATEWAY_DETAIL:'/admin/get_sms_gateway_detail',
  UPDATE_SMS_CONFIGURATION:'/admin/update_sms_configuration',
  GET_REFERRAL_HISTORY_LIST:'/admin/get_referral_detail',
  GET_TRANSPORTER_REFERRAL_LIST:'/admin/get_transporter_referral',
  TRANSPORTER_REFERRAL_LIST:'/admin/transporter_referral_list',
  GET_TRANSACTION_HISTORY_LIST:'/admin/get_transaction_history',
  GET_STORE_LIST_FOR_COUNTRY:'/admin/get_store_list_for_country',
  GET_STORE_LIST_FOR_CITY:'/admin/get_store_list_for_city',
  GET_USER_LIST:'/admin/get_user_list',
  ADD_ADVERTISE:'/admin/add_advertise',
  UPDATE_ADVERTISE:'/admin/update_advertise',
  DELETE_ADVERTISE:'/admin/delete_advertise',
  ADVERTISE_LIST:'/admin/advertise_list',
  GET_ADVERTISE_DETAIL:'/admin/get_advertise_detail',
  GET_MAIL_GATEWAY_DETAIL:'/api/admin/get_setting_detail_for_mail_config',
  GET_PROVIDER_LIST_FOR_CITY:'/admin/get_provider_list_for_city',
  GET_ORDER_LIST_FOR_LOCATION_TRACK:'/admin/order_list_location_track',
  DELIVERYMAN_TRACK:'/admin/deliveryman_track',
  GET_ACTIVITY_LOGS:'/get_activity_logs',

  GET_PRODUCT_LIST: '/api/store/get_product_list',
  GET_SOECIFICATION_LIST: '/api/store/get_specification_lists',
  ADD_ITEM: '/api/store/add_item',
  CANCEL_REQUEST: '/api/store/cancel_request',
  CREATE_REQUEST: '/api/store/create_request',
  GET_ITEM_DATA: '/api/store/get_item_data',
  GET_SPECIFICATION_LISTS: '/api/store/get_specification_lists',
  UPDATE_ITEM: '/api/store/update_item',
  UPDATE_ITEM_IMAGE: '/api/store/update_item_image',
  DELETE_ITEM_IMAGE: '/api/store/delete_item_image',
  GET_PRODUCT_DATA: '/api/store/get_product_data',
  ADD_PRODUCT_GROUP_DATA: '/api/store/add_product_group_data',
  GET_PRODUCT_GROUP_LIST: '/api/store/get_product_group_list',
  GET_PRODUCT_LIST_OF_GROUP: '/api/store/get_group_list_of_group',

  UPDATE_PRODUCT_GROUP_DATA: '/api/store/update_product_group',
  DELETE_PRODUCT_GROUP_DATA: '/api/store/delete_product_group',
  GET_PRODUCT_GROUP_DATA: '/api/store/get_product_group_data',
  UPDATE_PRODUCT: '/api/store/update_product',
  GET_STORE_PRODUCT_ITEM_LIST: '/api/store/get_store_product_item_list',
  SET_ORDER_STATUS: '/api/store/set_order_status',
  ADD_PRODUCT: '/api/store/add_product',
  GET_SPECIFICATION_GROUP: '/api/store/get_specification_group',
  ADD_SPECIFICATION_GROUP: '/api/store/add_specification_group',
  ADD_SPECIFICATION: '/api/store/add_specification',
  WEEKLY_EARNING: '/api/store/weekly_earning',
  COMPLETE_ORDER: '/api/store/complete_order',
  GET_STORE_PROVIDER_VEHICLE:'/admin/provider_vehicle_list',
  UPDATE_STORE_PROVIDER_VEHICLE:'/admin/provider_vehicle_update',
  GET_PAYMENT_GATEWAYS: '/api/store/get_payment_gateways',
  UPDATE_CASH_PAYMENT_GATEWAYS: '/api/store/update_cash_payment_gateways',
  UPDATE_PAYMENT_GATEWAYS: '/api/store/update_payment_gateways',
  PAYMENT_GATEWAY_LIST: '/admin/payment_gateway_list',
  LIST_ORDERS_HISTORY:'/api/admin/history',

  LIST_EARNING:'/admin/get_earning',
  FETCH_EARNING_DETAIL:'/admin/fetch_earning_detail',
  LIST_ORDERS:'/admin/admin_list_orders',
  DELIVERY_LIST: '/admin/admin_list_delivery',
  TABLE_LIST_ORDERS:'/admin/admin_table_list_orders',

  ALL_ORDER_HISTORY: '/admin/admin_order_history',
  ALL_DELIVERY_HISTORY: '/admin/admin_order_delivery_history',

  WEEKLY_UNPAID_TRANSPORTER: '/admin/dashboard/weekly_unpaid_transporter',
  EXPORT_WEEKLY_PAYMENT: '/admin/dashboard/export_weekly_payment',
  WEEKLY_REFERRAL_EARNING: '/admin/dashboard/weekly_referral_earning_transporter',
  MARK_REFERRAL_SUCCESSFUL: '/admin/dashboard/mark_as_paid_referral_earning',

  EXPORT_WEEKLY_REFERRAL_PAYMENT: 'admin/dashboard/export_weekly_referral_earning_transporter',

  MARK_PAID_TRANSACTIONS: '/admin/dashboard/paid_transaction',

  LIST_INSURANCE_CLAIMS:'/admin/insurance_claim_lists_search_sort',
  UPDATE_INSURANCE_CLAIM_STATUS:'/admin/update_insurance_status',
  TABLE_LIST_INSURANCE_CLAIMS:'/admin/insurance_claim_lists_search_sort',
  FETCH_INSURANCE_CLAIM_DETAIL:'/admin/insurance_details',
  FETCH_ORDER_DETAIL:'/admin/admin_fetch_order_detail',
  ORDER_DETAIL_BY_ID: '/admin/order_by_id',
  GET_TRANSPORTERS: '/admin/provider_list_search',
  MANUAL_ALLOCATION: '/admin/manual_allocation',
  GET_ORDER_REQUEST_LIST: '/admin/order_request_list',
  ORDER_READY_FOR_SHIPMENT: '/api/store/store_order_status',
  UPDATE_CUSTOMER_PHONE: '/admin/customer_phone_update',

  LIST_DELIVERIES:'/admin/admin_list_deliveries',

  LIST_STORE_EARNING: '/admin/store_weekly_earning',
  LIST_DELIVERYMAN_EARNING: '/admin/provider_weekly_earning',
  FETCH_DELIVERYMAN_EARNING: '/api/provider/weekly_earning',
  STORE_EARNING_LIST: '/admin/dashboard/store_earning_list',
  PAYMENT_HISTORY: '/admin/dashboard/payment_history',

  DELETE_WEIGHT_CATEGORY_PRICE: '/admin/delete_weight_category_price',
  DELETE_WEIGHT: '/admin/delete_weight_category ',



  GET_CITY_LISTS: '/api/admin/get_city_lists',
  GET_VEHICLE_LIST: '/api/admin/get_vehicle_list',
  GET_ZONE_DETAIL: '/admin/get_zone_detail',
  SERVICE_LIST: '/admin/service_list',
  ADD_SERVICE_DATA: '/admin/add_service_data',
  ADD_ZONE_PRICE: '/admin/add_zone_price',
  GET_SERVICE_DETAIL: '/admin/get_service_detail',
  UPDATE_SERVICE: '/admin/update_service',
  GET_IMAGE_SETTING: '/api/admin/get_image_setting',
  UPDATE_VEHICLE: '/admin/update_vehicle',
  GET_VEHICLE_DETAIL: '/admin/get_vehicle_detail',
  ADD_VEHICLE_DATA: '/admin/add_vehicle_data',
  GET_WALLET_REQUEST_LIST_SEARCH_SORT: '/admin/get_wallet_request_list_search_sort',
  GET_WALLET_REQUEST_BANK_DETAIL: '/admin/get_wallet_request_bank_detail',
  COMPLETE_WALLETE_REQUEST_AMOUNT: '/admin/complete_wallet_request_amount',
  APPROVE_WALLET_REQUEST_AMOUNT: '/admin/approve_wallet_request_amount',
  CANCLE_WALLET_REQUEST: '/admin/cancel_wallet_request',
  TRANSFER_WALLET_REQUEST_AMOUNT: '/admin/transfer_wallet_request_amount',
  UPDATE_DELIVERY: '/admin/update_delivery',
  DELETE_DELIVERY: '/admin/delete_delivery',
  ADD_DELIVERY_DATA: '/admin/add_delivery_data',
  ADD_DOCUMENT_DATA: '/admin/add_document_data',
  DOCUMENT_LIST: '/admin/document_list',
  UPDATE_DOCUMENT: '/admin/update_document',
  DELETE_DOCUMENT: '/admin/delete_document',
  GET_DETAILS: '/admin/get_detail',
  ADD_ADMIN: '/admin/add',
  UPDATE_ADMIN: '/admin/update',
  PRODUCT_FOR_CITY_STORE: '/admin/product_for_city_store',
  ITEM_FOR_CITY_STORE: '/admin/item_for_city_store',
  ORDER_DETAIL: '/admin/dashboard/order_detail',
  LAST_SIX_MONTH_PAYMENT_DETAIL: '/admin/dashboard/last_six_month_payment_detail',
  LAST_SIX_MONTH_EARNING_DETAIL: '/admin/dashboard/last_six_month_earning_detail',
  LAST_FIFTEEN_DAY_ORDER_DETAIL: '/admin/dashboard/last_fifteen_day_order_detail',
  MONTHLY_PAYMENT_DETAIL: '/admin/dashboard/monthly_payment_detail',

  CLAIM_DELIVERY_CHART_DATA: '/admin/dashboard/delivery_chart_data',
  COURIER_DATA_LIST: '/api/store/courier_data_list',

  // GET_VEHICLE_LIST_FOR_STORE: '/api/store/get_vehicle_list',
  FIND_NEAREST_PROVIDER:'/api/store/find_nearest_provider_list',
  GET_ORDER_DISPATCHER_ORDER_LIST: '/admin/get_admin_dispatcher_order_list',

  GET_STORE_VEHICLE_LIST: '/admin/get_vehicles_lists',
  USER_CANCLE_ORDER: '/api/user/user_cancel_order',
  ADD_NEW_LANGUAGE: '/admin/add_new_language',
  CANCEL_ORDER: '/admin/cancel_order',
  REACTIVATE_ORDER: '/order/reactive_order_status',
  RETRIGGER_DELIVERY_REQUEST: '/admin/retrigger_delivery_request',

  SEND_OTP: '/order/send_otp_to_customer',
  MARK_AS_DELIVERED: '/order/customer_otp_verify',
  ADD_ORDER_NOTES: '/admin/add_order_notes',
  GET_ORDER_NOTES: '/admin/get_order_notes',

  DELIVERY_LIST_SEARCH_SORT:'/api/admin/delivery_list_search_sort',
  ADMIN_REQUESTS_DETAIL: "/api/admin/admin_requests_detail",
  GET_DELIVERY_LIST:"/api/admin/get_delivery_list",


  ADD_NEW_STORE: '/admin/add_new_store',
  ADD_NEW_USER: '/admin/add_new_user',
  ADD_NEW_PROVIDER: '/admin/add_new_provider',
  UPDATE_DATABASE_TABLE: '/updateDatabaseTable',
  ADD_PROVIDER_VEHICLE_DATA: '/admin/add_provider_vehicle_data',
  UPDATE_ITEM_NEW_TABLE: '/updateItemNewTable',
  UPDATE_ZONE_PRICE: '/admin/update_zone_price',

  ADD_TAX: "/admin/add_tax",
  EDIT_TAX: "/admin/edit_tax",
  GET_LEGAL: "/admin/get_legal",
  UPDATE_LEGAL: "/admin/update_legal",

  ADD_CANCELLATION_REASON:'/admin/add_cancellation_reason',
  UPDATE_CANCELLATION_REASON:'/admin/update_cancellation_reason',
  DELETE_CANCELLATION_REASON:'/admin/delete_cancellation_reason',
  CANCELLATION_REASON_LIST:'/admin/cancellationreason_list',
  GET_CANCELLATION_REASON_DETAIL:'/admin/get_cancellation_reason',

  UPDATE_SEO_TAGS: '/api/admin/updateSeoTags',
  GET_SEO_TAGS: '/api/admin/getSeoTags',

  ADD_SCRIPT_TAG: '/api/admin/add_script_page',
  DELETE_USER: '/api/user/delete_account',
  DELETE_PROVIDER: '/api/provider/delete_account',
  DELETE_STORE: '/api/store/delete_account',
  SUSPEND_PROVIDER: '/admin/provider_suspend_unsuspend',

  GET_STORE_WAREHOUSE: "/api/store/get_store_warehouse_list",
  GET_STORE_ADDRESSES: "/api/store/get_store_address_list",
  
}
