import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivateChild,
} from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { map, take } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate, CanActivateChild {

  permissions: Array<any>;


  constructor(private authService: AuthService, private router: Router) { }
  
  async canActivateChild(route: ActivatedRouteSnapshot,state: RouterStateSnapshot): Promise<boolean> {
    let check_permission = route.data['auth'];
    // console.log(check_permission)

    if(this.authService.loginAdminData){
      if(!check_permission || this.authService.is_main_store_login){
        return true;
      }else{
        if(this.hasPermission(check_permission) && !this.permissions.includes(check_permission)){
          this.router.navigate(['/app/dashboard'])
        }
        return this.hasPermission(check_permission)
      }
    } else {
      return false
    }
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    let check_permission = route.data['auth'];
     //console.log('check_permission',check_permission)
    if(this.authService.loginAdminData){
      if(!check_permission || this.authService.is_main_store_login){
        return true;
      }else{
        return this.hasPermission(check_permission)
      }
    } else {
      return false
    }
  }



  hasPermission(check_permission): Promise<boolean> {
    return new Promise(async (resolve, rejects) => {
      if (!this.permissions) {
        await this.authService.authPermission.subscribe(permissions => {
          this.permissions = permissions;
        })
      }      
      resolve(this.permissions.includes(check_permission));
    })
  }


}


